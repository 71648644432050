import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { agencyTheme } from 'common/src/theme/agency';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, AgencyWrapper } from '../containers/Agency/agency.style';
import Navbar from '../containers/Agency/Navbar';
import ServicesSection from '../containers/Agency/ServicesSection';
import HighlightSection from '../containers/Agency/HighlightSection';
// import FaqSection from '../containers/Agency/FaqSection';
import ContactUsSection from '../containers/Agency/ContactUsSection';
import Banner from '../containers/Interior/Banner';
import News from '../containers/Interior/News';
// import Footer from '../containers/Agency/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';

export default () => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="Development Agency" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar page="home" />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <ServicesSection />
          <HighlightSection page="home" />
          <News />
          {/*<FaqSection />*/}
          <ContactUsSection netlifyForm="contact-home" />
          {/*<Footer />*/}
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
