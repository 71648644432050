import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import SectionWrapper, {
  ContentWrapper,
  NewsWrapper,
  NewsItem,
} from './news.style';

const News = ({ sectionHeader, sectionTitle, sectionSubTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        newsData {
          id
          title
          excerpt
          link_url
          link_text
        }
      }
    }
  `);

  return (
    <SectionWrapper id="blogSection">
      <ContentWrapper>
        <div className="row">
          <div className="col">
            <Box {...sectionHeader}>
              <Text content="OUR BLOG" {...sectionSubTitle} />
              <Heading content="Read more about what we do" {...sectionTitle} />
            </Box>
          </div>
        </div>
        <NewsWrapper>
          {data.interiorJson.newsData.map((item) => (
            <NewsItem key={`news_key${item.id}`}>
              <Fade bottom delay={60 * item.id}>
                <Heading as="h3" content={item.title} />
                <Text content={item.excerpt} />
                <a href={item.link_url} className="read_more__btn">
                  <span className="arrow"></span>
                  <span className="text">{item.link_text}</span>
                </a>
              </Fade>
            </NewsItem>
          ))}
        </NewsWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
};

// ServicesSection style props
News.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
};

// ServicesSection default style
News.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#10ac84',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
};

export default News;
