import React, { Fragment, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { CircleLoader } from '../interior.style';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BannerWrapper, {
  Container,
  ContentArea,
  FormWrapper,
  ButtonGroup,
  CarouselArea,
} from './banner.style';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        bannerData {
          discount
          discountLabel
          title
          text
          callToAction
          carousel {
            id
            title
            thumb_url {
              publicURL
            }
            link
          }
        }
      }
    }
  `);

  const { discount, discountLabel, title, text, carousel, callToAction } =
    data.interiorJson.bannerData;

  const glideOptions = {
    type: 'carousel',
    perView: 3,
    gap: 20,
    breakpoints: {
      1200: {
        perView: 2,
      },
      667: {
        perView: 2,
      },
      480: {
        perView: 1,
      },
    },
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
  }, []);

  const [state, setState] = useState({ email: '', valid: '' });
  const [sent, setSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  //const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line

  const handleFormData = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubscriptionForm = (e) => {
    e.preventDefault();
    setIsSending(true);
    const myForm = e.target;
    let formData = new FormData(myForm);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setSent(true);
        setIsSending(false);
        console.log('Form successfully submitted');
      })
      .catch((error) => {
        alert(error);
        setIsSending(false);
      });
  };

  return (
    <BannerWrapper>
      <Container>
        <ContentArea>
          <Fade bottom delay={30}>
            {/*<HighlightedText>
              <strong>{discount}</strong> {discountLabel}
              </HighlightedText>*/}
            <Heading as="h1" content={title} />
            <Text content={text} />
            <Text content={callToAction} />
            <FormWrapper
              id="bannerForm"
              data-netlify="true"
              name="banner"
              method="POST"
              onSubmit={(e) => handleSubscriptionForm(e)}
            >
              <input type="hidden" name="form-name" value="banner" />
              <Input
                className={state.valid}
                type="email"
                placeholder="Enter email address"
                icon={<Icon icon={iosEmailOutline} />}
                iconPosition="left"
                required={true}
                onChange={(e) => handleFormData(e, 'email')}
                aria-label="email"
                netlifyName="email"
              />
              <ButtonGroup>
                <Button
                  type="submit"
                  colors="primaryWithBg"
                  title={sent ? 'CONTACT REQUESTED' : 'REQUEST CONTACT'}
                  disabled={sent || isSending}
                  isLoading={isSending}
                />
                <AnchorLink href={'#servicesSection'} className="explore-more">
                  MORE <i className="flaticon-next" />
                </AnchorLink>
              </ButtonGroup>
            </FormWrapper>
          </Fade>
        </ContentArea>
        {/* End of content section */}

        <CarouselArea>
          {loading ? (
            <GlideCarousel
              carouselSelector="interior_carousel"
              options={glideOptions}
              nextButton={<span className="next_arrow" />}
              prevButton={<span className="prev_arrow" />}
            >
              <Fragment>
                {carousel.map((item) => (
                  <GlideSlide key={`carousel_key${item.id}`}>
                    <a href={item.link} className="item_wrapper">
                      <Image
                        src={item.thumb_url.publicURL}
                        alt={item.title}
                        className="carousel_img"
                      />
                      <Heading as="h4" content={item.title} />
                    </a>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          ) : (
            <CircleLoader>
              <div className="circle"></div>
              <div className="circle"></div>
            </CircleLoader>
          )}
        </CarouselArea>
        {/* End of carousel section */}
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
